const texts = {
  headers: {
    clientName: 'Client Name',
    coach: 'Coach',
    product: 'Product',
    startDate: 'Start Date',
    initialPaymentAndTerm: 'Initial Payment & Term',
    monthly: 'Monthly',
    status: 'Status',
    contractAction: 'Contract Action',
  },
  defaultRowValue: '-',
  copy: 'Copy',
  cancel: 'Cancel',
  update: 'Update',
  reset: 'Reset',
  retryPayment: 'Retry Payment',
  copiedSuccessfully: 'User onboarding link copied successfully',
  paymentAndTerm: '{initialPayment} for {initialTerm} month(s)',
};

export default texts;
