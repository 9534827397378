import React, {
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';

import format from 'string-template';
import FirebaseContext from '../../../context/FirebaseContext';
import {
  listInvoices,
  retryInvoicePayment,
} from '../../../pages/Onboarding/Subscription/stripe/SubscriptionRemoteRequestHandler';
import useComponentMounted from '../../../hooks/useComponentMounted';
import UserContract from '../../../Model/UserContract';
import { ContractStatus } from '../../utils/userContract';
import Subscription from '../../../Model/Subscription';
import Product from '../../../Model/Product';
import Lead from '../../Model/Lead';
import { DateFormat } from '../../../utils/date';
import InternalAssignmentContext, {
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
} from '../../context/InternalAssignmentsContext';
import UserSelect from '../../pages/Analytics/components/UserSelect';
import DateFilter from '../DateFilter';
import { ALL, DaysOptions } from '../DateFilter/utils';
import ColoredHeader from '../ColoredHeader';
import LoadingOverlay from '../LoadingOverlay';
import ConfirmDialog from '../ConfirmDialog';
import PageContent from '../PageContent';
import useToast from '../../hooks/useToast';

import { LeadContractModal } from '../ContractModal';
import ContractsTable from './components/ContractsTable';

import {
  Container,
  Title,
  TitleContainer,
  FilterOptionsContainer,
} from './styles';
import texts from './texts.json';

const ContractsManager = () => {
  const { params: { userId } } = useRouteMatch();
  const { showToast } = useToast();
  const { isUsers } = useContext(InternalAssignmentContext);
  const { firebase: { remote } } = useContext(FirebaseContext);
  const isComponentMountedRef = useComponentMounted();

  const [contractsCollection, setContractsCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [contractToCancel, setContractToCancel] = useState(null);
  const [contractToRetryPayment, setContractToRetryPayment] = useState(null);
  const [productsCollection, setProductsCollection] = useState({ docs: [] });
  const [selectedPeriod, setSelectedPeriod] = useState(DaysOptions[0]);
  const [selectedContract, setSelectedContract] = useState();
  const [selectedContractLeadDoc, setSelectedContractLeadDoc] = useState(null);
  const [selectedISAgent, setSelectedISAgent] = useState(() => {
    const agent = isUsers.find(({ userId: agentId }) => agentId === userId);
    if (agent) {
      return {
        id: agent.userId,
        label: agent.userName,
      };
    }
    return null;
  });

  const clearSelectedContract = useCallback(() => {
    setSelectedContract(null);
    setSelectedContractLeadDoc(null);
  }, []);

  const isAgentsWithAllOption = useMemo(() => [{
    userName: texts.allISAgents,
    userId: '',
  }, ...isUsers], [isUsers]);

  useEffect(() => {
    const loadContracts = async () => {
      setIsLoading(true);
      const loadUserContractsFrom = selectedPeriod === ALL ? null
        : moment().subtract(selectedPeriod, 'days').format(DateFormat.DEFAULT_DATE_FORMAT);

      const contractsCol = await UserContract.getContractsBySellerIdAndStatuses(
        selectedISAgent.id,
        [
          ContractStatus.INCOMPLETE,
          ContractStatus.PENDING,
          ContractStatus.INCOMPLETE_EXPIRED,
        ],
        loadUserContractsFrom,
      );

      if (isComponentMountedRef.current) {
        setContractsCollection(contractsCol);
        setIsLoading(false);
      }
    };

    if (selectedISAgent) {
      loadContracts();
    } else {
      setIsLoading(false);
      setContractsCollection(null);
    }
  }, [isComponentMountedRef,
    selectedISAgent,
    selectedPeriod,
  ]);

  useEffect(() => {
    const loadLeadOfSelectedContract = async () => {
      const leadDoc = await Lead.getLeadById(selectedContract.leadId);

      if (leadDoc) {
        if (isComponentMountedRef.current) {
          setSelectedContractLeadDoc(leadDoc);
        }
      } else {
        const message = format(texts.contractModalOpenFailureMessage, {
          reason: format(texts.leadDocNotFound, {
            leadId: selectedContract.leadId,
          }),
        });
        showToast(message, { error: true });
        clearSelectedContract();
      }
    };
    if (selectedContract?.leadId) {
      loadLeadOfSelectedContract();
    }
  }, [
    selectedContract,
    isComponentMountedRef,
    showToast,
    clearSelectedContract,
  ]);

  useEffect(() => {
    const loadProducts = async () => {
      const productsCol = await Product.getAllProducts();
      if (isComponentMountedRef.current) {
        setProductsCollection(productsCol);
      }
    };
    loadProducts();
  }, [isComponentMountedRef]);

  // The Products holds the name of the product mapped from its IDs.
  const products = useMemo(() => (
    productsCollection.docs.reduce((acc, product) => {
      acc[product.id] = product.name;
      return acc;
    }, {})
  ), [productsCollection]);

  const handleOpenContractModal = (contract) => {
    if (contract.leadId) {
      setSelectedContract(contract);
    } else {
      const message = format(texts.contractModalOpenFailureMessage, { reason: texts.noLeadIdError });
      showToast(message, { error: true });
    }
  };

  const handleCancel = useCallback(async () => {
    setIsLoading(true);
    try {
      await contractToCancel.updateStatus(ContractStatus.CANCELED);
      showToast(format(texts.cancelSuccessMessage, {
        userName: contractToCancel.userName,
      }), { type: 'success' });
    } catch (error) {
      showToast(format(texts.cancelFailureMessage, {
        userName: contractToCancel.userName,
      }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setContractToCancel(null);
        setIsLoading(false);
      }
    }
  }, [
    showToast,
    setIsLoading,
    isComponentMountedRef,
    contractToCancel,
  ]);

  const handleRetryPayment = useCallback(async () => {
    setIsLoading(true);
    if (!contractToRetryPayment.userId) {
      showToast(format(texts.retryError, { error: texts.noUserId }), { error: true });
      setIsLoading(false);
      return;
    }

    try {
      const invoices = await listInvoices(contractToRetryPayment.userId, remote);
      if (invoices.length === 0) {
        showToast(format(texts.retryError, { error: texts.noCurrentInvoice }), { error: true });
        setIsLoading(false);
        return;
      }
      const subscriptionDoc = await Subscription.getCurrentSubscription(contractToRetryPayment.userId);
      const currentInvoice = invoices.sort((first, second) => second.created - first.created)[0];
      const { invoice, error: stripeError } = await retryInvoicePayment({
        invoiceId: currentInvoice.id,
        stripeAccountId: subscriptionDoc.stripeAccountId,
        remote,
      });

      const invoiceDate = moment.unix(currentInvoice.created).format(DateFormat.MONTH_NAME_DATE_FORMAT) || '';

      if (stripeError) {
        showToast(format(texts.retryError, { invoiceDate, error: stripeError.message || '' }), { error: true });
      } else if (invoice) {
        showToast(format(texts.retrySuccess, { invoiceDate }));
      }
    } catch (error) {
      showToast(format(texts.retryError, { error: error.message || texts.unknownError }), { error: true });
    } finally {
      setContractToRetryPayment(null);
      setIsLoading(false);
    }
  }, [
    contractToRetryPayment,
    remote,
    showToast,
  ]);

  const handleReset = useCallback(async (contractDoc) => {
    setIsLoading(true);
    try {
      await contractDoc.updateStatus(ContractStatus.PENDING);
      showToast(format(texts.resetSuccessMessage, {
        userName: contractDoc.userName,
      }));
    } catch (error) {
      showToast(format(texts.resetFailureMessage, {
        userName: contractDoc.userName,
      }), { error: true });
    } finally {
      if (isComponentMountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [
    showToast,
    setIsLoading,
    isComponentMountedRef,
  ]);

  const closeConfirmDialog = () => {
    setContractToCancel(null);
    setContractToRetryPayment(null);
  };

  return (
    <Container>
      <ColoredHeader>
        <TitleContainer>
          <Title>{texts.ContractsManagerTitle}</Title>
        </TitleContainer>
        <FilterOptionsContainer>
          <UserSelect
            selectedUser={selectedISAgent}
            userList={isAgentsWithAllOption}
            onChange={setSelectedISAgent}
            inputLabel={texts.inputLabel}
            inputPlaceHolder={texts.inputPlaceHolder}
          />
          <DateFilter
            selectedPeriod={selectedPeriod}
            onChange={setSelectedPeriod}
          />
        </FilterOptionsContainer>
      </ColoredHeader>
      <PageContent>
        <ContractsTable
          rows={contractsCollection?.docs.slice() || []}
          productsList={products}
          handleOpenContractModal={handleOpenContractModal}
          onClickCancel={setContractToCancel}
          onClickRetryPayment={setContractToRetryPayment}
          onclickReset={handleReset}
        />
      </PageContent>
      <LoadingOverlay isLoading={isLoading} />
      {!!selectedContractLeadDoc && (
        <LeadContractModal
          lead={selectedContractLeadDoc}
          coachId={selectedContract?.coach || ''}
          showModal={!!selectedContractLeadDoc}
          onClose={clearSelectedContract}
        />
      )}
      <ConfirmDialog
        isOpen={!!contractToCancel || !!contractToRetryPayment}
        onConfirm={contractToCancel ? handleCancel : handleRetryPayment}
        onCancel={closeConfirmDialog}
        dialogTexts={{
          title: contractToCancel ? texts.deleteConfirm : texts.confirmRetry.title,
          content: contractToCancel ? '' : format(texts.confirmRetry.content, {
            userName: contractToRetryPayment?.userName,
          }),
        }}
      />
    </Container>
  );
};

export default compose(
  withInternalAssignmentsContextProvider,
  withInternalAssignmentsContextReady,
  observer,
)(ContractsManager);
